import {Dispatch, SetStateAction, useEffect, useState, Fragment} from "react";
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import {
    addCXNotification,
    manualFailVerification,
    manualPaymentVerification,
    manualSuccessVerification,
    sendCustomNotification,
    sendEmail,
    sendSMS
} from "../../../../services/idv.service";
import PhoneInput from "react-phone-number-input";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {emailPattern, phonePattern} from "../../../../utils/patterns";
import {CustomPhoneInput} from "../../../common/components/CustomPhoneInput";
import useCountDown from "react-countdown-hook";
import {canAccess} from "../../../../utils/permissions";
import {StepNames, VerificationStatus} from "../../constants";

export interface IVerificationDetailActionsProps {
    verification: any;
    isReservationCancelled: boolean | undefined;
    setActionLoading: Dispatch<SetStateAction<boolean>>;
    onListRefresh: () => Promise<void> | undefined;
    disabledActions: boolean;
    refreshVerification: () => any;
    actionLoading: boolean;
    lock: any;
    reasons: Array<any>;
    setReasons: Dispatch<SetStateAction<any>>;
}

export const VerificationDetailActions = (props: IVerificationDetailActionsProps) => {
    const {
        verification,
        isReservationCancelled,
        setActionLoading,
        onListRefresh,
        disabledActions,
        refreshVerification,
        actionLoading,
        lock,
        reasons,
        setReasons
    } = props;

    //FLAGS
    const [reasonTypeAllow, setReasonTypeAllow] = useState(false);
    const [reasonTypeDeny, setReasonTypeDeny] = useState(false);
    const [reasonDetail, setReasonDetail] = useState("");
    const [action, setAction] = useState("");
    const [buttonColor, setButtonColor] = useState("info");
    const [buttonColorPayment, setButtonColorPayment] = useState("info");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [emailIsTouched, setEmailIsTouched] = useState(false);
    const [phoneIsTouched, setPhoneIsTouched] = useState(false);
    const [isSendToAnother, setIsSendToAnother] = useState(false);
    const [isIncomplete, setIsIncomplete] = useState(false);
    const [isPassed, setIsPassed] = useState(false);
    const [isManualCanceled, setIsManualCanceled] = useState(false);
    const [isManualApprovePayment, setIsManualApprovePayment] = useState(false);
    const [isManualDenyPayment, setIsManualDenyPayment] = useState(false);

    //PERMISSIONS
    const canVerificationOverride = canAccess("verification-override");
    const canVerificationResendEmail = canAccess("verification-resend-email");

    //OTHERS
    const [,{start}] = useCountDown(10000);

    //FORM VALIDATIONS
    const emailIsValid = email === "" || emailPattern.test(email);
    const phoneIsValid = phone === undefined || (phonePattern.test(phone) && phone.length > 8 && phone.length < 20);
    const emailError = emailIsValid ? " " : "Invalid email";
    const phoneError = phoneIsValid ? " " : "Invalid phone number";
    const isFinished = verification?.verification?.status === "PASSED" || verification?.verification?.status === "FAILED";
    const disableCustomSend = actionLoading || !emailIsValid || !phoneIsValid || (email === "" && phone === undefined);


    // ALLOW & DENY MANUAL
    const onChangeReasonTypeAllow = async (e: any) => {
        setReasonTypeAllow(e.target.checked);
    }

    const onChangeReasonTypeDeny = async (e: any) => {
        setReasonTypeDeny(e.target.checked);
    }

    const onChangeManualApprovePayment = async (e: any) => {
        setIsManualApprovePayment(e.target.checked);
        if (e.target.checked) {
            setButtonColorPayment("success");
            if (isManualDenyPayment) {
                setIsManualDenyPayment(!e.target.checked);
            }
        }
    }

    const onChangeManualDenyPayment = async (e: any) => {
        setIsManualDenyPayment(e.target.checked);
        if (e.target.checked) {
            setButtonColorPayment("error");
            if (isManualApprovePayment) {
                setIsManualApprovePayment(!e.target.checked);
            }
        }
    }

    const applyAllowedDeniedReason = async () => {
        if ((!reasonTypeAllow && !reasonTypeDeny) || !reasonDetail) {
            return;
        }
        if (reasonTypeAllow) {
            await onClickOverwriteAsPassed();
        } else if (reasonTypeDeny) {
            await onClickManualCancel();
        }
    }

    const onClickOverwriteAsPassed = async () => {
        try {
            // eslint-disable-next-line no-restricted-globals
            const validate = confirm("Are you sure you want to overwrite the previous status and mark this as passed)");
            if (validate) {
                setActionLoading(true);
                const reasonId = reasons.find(item => item.reason === reasonDetail)?.id;
                await manualSuccessVerification(verification?.verification.id, reasonId, reasonDetail);
                await refreshVerification();
                refreshManualStatus();
            }
        } catch (e) {
            alert("There was an error.");
        } finally {
            setActionLoading(false);
        }
    };

    const onClickManualCancel = async () => {
        try {
            if (isReservationCancelled) {
                // eslint-disable-next-line no-restricted-globals
                const validate = confirm("Are you sure you want to overwrite the previous status and mark this as cancelled)");

                if (validate) {
                    setActionLoading(true);
                    const reasonId = reasons.find(item => item.reason === reasonDetail)?.id;
                    await manualFailVerification(verification?.verification.id, reasonId, reasonDetail);

                    if (typeof onListRefresh === 'function') {
                        await onListRefresh();
                    }

                    await refreshVerification();
                    refreshManualStatus();
                }
            } else {
                alert(
                    "Dear user, we notice the reservation is not cancelled. Please go to resedit to cancel it first and then confirm it as cancelled"
                );
            }
        } catch (err) {
            alert("There was an error.");
        } finally {
            setActionLoading(false);
        }
    };

    const onClickManualPaymentVerification = async () => {
        try {
            // eslint-disable-next-line no-restricted-globals
            const validate = confirm(`Are you sure you want to manually mark this payment validation as ${isManualApprovePayment ? "passed" : "declined"}?`);
            if (validate) {
                setActionLoading(true);
                await manualPaymentVerification(verification?.verification.id, isManualApprovePayment);
                await refreshVerification();
                refreshManualStatus();
            }
        } catch (e) {
            alert("There was an error.");
        } finally {
            setActionLoading(false);
        }
    };

    const refreshManualStatus = () => {
        setIsManualApprovePayment(false);
        setIsManualDenyPayment(false);
        setReasonTypeAllow(false);
        setReasonTypeDeny(false);
        setReasonDetail("");
    }

    // NOTIFICATIONS
    const onClickDoIt = async () => {
        if (verification === undefined) {
            return;
        }
        try {
            if (action === "phone") {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("This will add a 'Guest contacted' to the client's history.")) {
                    setActionLoading(true);
                    await addCXNotification(verification?.verification.id);
                    await refreshVerification();
                }
            } else if (action === "send-sms") {
                // eslint-disable-next-line no-restricted-globals
                if (confirm("This will send/resend the identity verification sms to the guest.")) {
                    setActionLoading(true);
                    await sendSMS(verification?.verification.id);
                    await refreshVerification();
                }
            } else if (action === "resend") {
                setActionLoading(true);
                // eslint-disable-next-line no-restricted-globals
                if (confirm("This will resend the identity verification email to the guest.")) {
                    await sendEmail(verification?.verification.id);
                    await refreshVerification();
                }
            } else if (action === "send-another-recipient") {
                await onClickSend();
            }
        } catch (err) {
            alert("There was an error.");
        } finally {
            setActionLoading(false);
        }
    };

    const onClickSend = async () => {
        if (verification === undefined) {
            return;
        }
        if (email === "" && phone === undefined) {
            return;
        }
        try {
            setActionLoading(true);
            if (email !== "") {
                await sendCustomNotification("email", verification?.verification?.id, email);
            }
            if (phone !== undefined) {
                await sendCustomNotification("sms", verification?.verification?.id, phone);
            }
            await refreshVerification();
            start(10000);
        } catch (err) {
            alert("There was an error.");
        } finally {
            setActionLoading(false);
        }
    };


    // USE EFFECT
    useEffect(() => {
        setIsIncomplete(verification?.verification?.status === VerificationStatus.IN_PROGRESS || verification?.verification?.status === VerificationStatus.IN_PROGRESS_RETRY);
        setIsPassed(verification?.verification?.status === VerificationStatus.PASSED);
        setIsManualCanceled(verification?.verification?.status_detail === StepNames.MANUAL_CANCEL);
    }, [verification]);

    useEffect(() => {
        setReasonDetail("");
        const reason = reasonTypeAllow ? "allowed" : reasonTypeDeny ? "denied" : null;
        setReasons(reasons.map((item) => ({...item, show: reason === item.type.toLowerCase()})));
        setButtonColor(reason === "allowed"? "success": reason === "denied" ? "error" : "inherit")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reasonTypeAllow, reasonTypeDeny]);

    useEffect(() => {
        if (reasonTypeAllow) {
            setReasonTypeDeny(false);
        }
    }, [reasonTypeAllow]);

    useEffect(() => {
        if (reasonTypeDeny) {
            setReasonTypeAllow(false);
        }
    }, [reasonTypeDeny]);

    useEffect(() => {
        setIsSendToAnother(action === "send-another-recipient");
    }, [action]);


    // DISABLED FORM
    const isPaymentValidationFailed = !!verification.is_payment_failed;
    const isPaymentValidationMissing = verification.is_payment_missing;
    const isDisabledAllowOption = isPassed || isManualCanceled || lock?.isLocked || isPaymentValidationFailed;
    const isDisabledDenyOption = isManualCanceled || lock?.isLocked || isPaymentValidationFailed;
    const isDisabledAllowOptionPayment = isPassed || isManualCanceled || lock?.isLocked || !isPaymentValidationMissing;
    const isDisabledDenyOptionPayment = isManualCanceled || lock?.isLocked || !isPaymentValidationMissing;
    const enableManualPaymentVerification = true;

    return (
        <Fragment>
            {canVerificationOverride && (
                <Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <b style={{color: "#524E4A"}}>Manual Authorization Identity:</b>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={6}>
                                    <FormControlLabel onChange={onChangeReasonTypeAllow} disabled={isDisabledAllowOption} checked={reasonTypeAllow} labelPlacement="top" control={<Checkbox sx={{color: "#99ADB6"}}/>} label="Allow" />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel onChange={onChangeReasonTypeDeny} disabled={isDisabledDenyOption} checked={reasonTypeDeny} labelPlacement="top" control={<Checkbox sx={{color: "#99ADB6"}}/>} label="Deny" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={reasonDetail}
                                    onChange={(e) => {
                                        setReasonDetail(e.target.value || "");
                                    }}
                                    inputProps={{"aria-label": "Without label"}}
                                    disabled={!reasonTypeAllow && !reasonTypeDeny}
                                    style={{ marginTop: 20}}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                >
                                    {reasons.map((reason) => (
                                        reason.show && <MenuItem
                                            key={reason.reason}
                                            value={reason.reason}
                                        >
                                            {reason.reason}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Button
                                color={buttonColor as any}
                                variant="contained"
                                fullWidth
                                style={{textTransform: "none", height: 40, marginTop: 20}}
                                onClick={applyAllowedDeniedReason}
                                disabled={disabledActions || lock?.isLocked || !reasonDetail}
                            >
                                Apply
                            </Button>
                        </Grid>

                        { enableManualPaymentVerification &&
                            <>
                                <Grid item xs={12} sm={12}>
                                    <b style={{color: "#524E4A"}}>Manual Authorization Payments:</b>
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Grid container rowSpacing={1}>
                                        <Grid item xs={6}>
                                            <FormControlLabel onChange={onChangeManualApprovePayment} disabled={isDisabledAllowOptionPayment} checked={isManualApprovePayment} labelPlacement="top" control={<Checkbox sx={{color: "#99ADB6"}}/>} label="Allow" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel onChange={onChangeManualDenyPayment} disabled={isDisabledDenyOptionPayment} checked={isManualDenyPayment} labelPlacement="top" control={<Checkbox sx={{color: "#99ADB6"}}/>} label="Deny" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5} sm={5}></Grid>
                                <Grid item xs={4} sm={4}>
                                    <Button
                                        color={buttonColorPayment as any}
                                        variant="contained"
                                        fullWidth
                                        style={{textTransform: "none", height: 40, marginTop: 20}}
                                        onClick={onClickManualPaymentVerification}
                                        disabled={lock?.isLocked || (!isManualApprovePayment && !isManualDenyPayment) || !isPaymentValidationMissing}
                                    >
                                        Apply
                                    </Button>
                                </Grid>
                            </>
                        }
                        {isPaymentValidationFailed &&
                            <Grid item xs={12} sm={12} className="action-disabled-no-payment" color="error">
                                <Typography color="error">
                                    Alert: Manual authorization has been disabled because the payment validation has failed. This reservation is considered RISKY.
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <Box className="section-divider" style={{marginBottom: 16, marginTop: 16}} />
                </Fragment>
            )}

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <b style={{color: "#524E4A"}}>Notifications:</b>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl fullWidth size="small" disabled={disabledActions} style={{marginBottom: 8}}>
                        <Select
                            disabled={lock?.isLocked}
                            value={action}
                            onChange={(e) => {
                                setAction(e.target.value || "");
                            }}
                            inputProps={{"aria-label": "Without label"}}
                        >
                            <MenuItem value="">
                                <em>Choose an action</em>
                            </MenuItem>
                            <MenuItem value={"phone"}>Add guest contacted to history</MenuItem>
                            {canVerificationResendEmail && <MenuItem disabled={isFinished} value={"resend"}>Resend Email</MenuItem>}
                            {canVerificationResendEmail && <MenuItem value={"send-sms"} disabled={isPassed}>
                                {isIncomplete? "Send Incomplete Process Reminder SMS" : !isPassed ? "Send Failed Reminder SMS" : "Send SMS"}
                            </MenuItem>
                            }
                            {canVerificationResendEmail && <MenuItem value={"send-another-recipient"} disabled={isFinished} >Send to Another Address (Email/SMS)</MenuItem>}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        disabled={disabledActions || lock?.isLocked || !action || (action === "send-another-recipient" && disableCustomSend) }
                        onClick={onClickDoIt}
                        variant="contained"
                        fullWidth
                        style={{textTransform: "none", height: 40}}
                        sx={{ backgroundColor: "#0078AB" }}
                    >
                        Do it
                    </Button>
                </Grid>
            </Grid>

            {isSendToAnother &&
                <Grid container px={{marginTop: 20}} justifyContent="center" >
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12} sm={8}>
                            <Grid container>
                                <Grid item xs={12} sm={3}>
                                    <Box style={{marginTop: 8}}>Email</Box>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Box sx={{ display: "flex", marginBottom: 1}}>
                                        <MailOutlineIcon fontSize={"large"} sx={{ color: "action.active" }} style={{marginRight: 9, marginLeft: -4}}/>
                                        <TextField
                                            disabled={isFinished || lock?.isLocked}
                                            size="small"
                                            fullWidth
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            onBlur={() => setEmailIsTouched(true)}
                                            error={emailIsTouched && !emailIsValid}
                                            helperText={emailIsTouched && !emailIsValid ? emailError : ""}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Box style={{marginTop: 8}}>Phone Number</Box>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <PhoneInput
                                        disabled={isFinished || lock?.isLocked}
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="US"
                                        value={phone}
                                        onChange={setPhone}
                                        inputComponent={CustomPhoneInput}
                                        onBlur={() => setPhoneIsTouched(true)}
                                        name={phoneIsTouched && !phoneIsValid ? "error" : ""}
                                    />
                                    <Box
                                        style={{
                                            fontSize: 12,
                                            marginTop: 6,
                                            marginLeft: 45,
                                            fontWeight: 400,
                                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                            color: "#D62E4F",
                                            height: phoneIsTouched && !phoneIsValid ? undefined : 14,
                                        }}
                                        color="error"
                                    >
                                        {phoneIsTouched && !phoneIsValid ? phoneError : " "}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} justifyContent="center">
                            <Box style={{
                                display: "flex",
                                justifyContent:"center",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#524E4A",
                                fontSize: 14,
                                marginTop: 20
                            }}>
                                <ErrorOutlineIcon />
                                <span style={{maxWidth: 200}}>You can only use one new destination at a time</span>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Fragment>
    );
}
